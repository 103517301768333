const dataAllProducts = [
  {
    id: "пицца-1",
    category: "пицца",
    title: "Чесночная",
    descr: `сыр "Моцарелла", копченое куриное филе халяль, томаты, томатный соус, кунжут, чесночный соус`,
    price: 420,
    weight: "",
  },
  {
    id: "пицца-2",
    category: "пицца",
    title: "Цезарь",
    descr: `сыр "Моцарелла", куриное филе халяль, листья салата ,оригинальный соус "Цезарь", сыр "Пармезан", томатный соус`,
    price: 450,
    weight: "",
  },
  {
    id: "пицца-3",
    category: "пицца",
    title: "Ди-Поло",
    descr: `сыр "Моцарелла", куриное филе халяль, шампиньоны, тушеные в сливках, томаты, маслины, томатный соус`,
    price: 470,
    weight: "",
  },
  {
    id: "пицца-4",
    category: "пицца",
    title: "Сезон охоты",
    descr: `сыр "Моцарелла", охотничьи колбаски, томаты, опята маринованные, маслины, томатный соус`,
    price: 480,
    weight: "",
  },
  {
    id: "пицца-5",
    category: "пицца",
    title: "Мексиканская",
    descr: `сыр "Моцарелла", охотничьи колбаски, перец болгарский, пепперони, томаты, томатный соус (острота на выбор)`,
    price: 460,
    weight: "",
  },
  {
    id: "пицца-6",
    category: "пицца",
    title: "Скандинава",
    descr: `сыр "Моцарелла", норвежский лосось, листья салата, тигровые креветки, помидорки черри, творожный сыр`,
    price: 520,
    weight: "",
  },
  {
    id: "пицца-7",
    category: "пицца",
    title: "Пепперони",
    descr: `сыр "Моцарелла", томатный соус, томаты, пепперони, сыр "Пармезан"`,
    price: 430,
    weight: "",
  },
  {
    id: "пицца-8",
    category: "пицца",
    title: "Маргарита",
    descr: `сыр "Моцарелла", томатный соус, томаты, оливковое масло`,
    price: 400,
    weight: "",
  },
  {
    id: "пицца-9",
    category: "пицца",
    title: "Гавайская",
    descr: `сыр "Моцарелла", фирменный соус "Цезарь", копченое куриное филе, ананасы`,
    price: 430,
    weight: "",
  },
  {
    id: "пицца-10",
    category: "пицца",
    title: "Мясная",
    descr: `сыр  "Моцарелла", томатный  соус , томаты, копченое куриное филе, пепперони, охотничьи колбаски`,
    price: 510,
    weight: "",
  },
  {
    id: "холодные-роллы-1",
    category: "холодные-роллы",
    title: "Филадельфия Классическая",
    descr: `рис, норвежский лосось, сливочный сыр, авокадо, нори`,
    price: 340,
    weight: "",
  },
  {
    id: "холодные-роллы-2",
    category: "холодные-роллы",
    title: "Филадельфия Лайт",
    descr: `рис, норвежский лосось, сливочный сыр, огурец, икра "Macaro", нори`,
    price: 320,
    weight: "",
  },
  {
    id: "холодные-роллы-3",
    category: "холодные-роллы",
    title: "Филадельфия Люкс",
    descr: `рис, норвежский лосось, творожный сыр, икра красная, нори`,
    price: 360,
    weight: "",
  },
  {
    id: "холодные-роллы-4",
    category: "холодные-роллы",
    title: "Ниссин",
    descr: `рис, норвежский лосось, сливочный сыр, огурец, икра "Macaro", нори, фирменная шапочка`,
    price: 330,
    weight: "",
  },
  {
    id: "холодные-роллы-5",
    category: "холодные-роллы",
    title: "Сакура",
    descr: `рис, творожный сыр, копченое куриное филе халяль, листья салата, огурец, кунжут, нори, фирменная шапочка`,
    price: 320,
    weight: "",
  },
  {
    id: "холодные-роллы-6",
    category: "холодные-роллы",
    title: "Овощной",
    descr: `рис, листья салата ,перец болгарский, огурец, томаты, авокадо, нори`,
    price: 300,
    weight: "",
  },
  {
    id: "холодные-роллы-7",
    category: "холодные-роллы",
    title: "Калифорния",
    descr: `рис, тигровые креветки, сливочный сыр, огурец, авокадо, икра "Macaro", нори`,
    price: 330,
    weight: "",
  },
  {
    id: "холодные-роллы-8",
    category: "холодные-роллы",
    title: "Цезарь с курицей",
    descr: `рис, томаты, куриное филе халяль, листья салата, сыр "Пармезан", соус "Цезарь", нори`,
    price: 330,
    weight: "",
  },
  {
    id: "холодные-роллы-9",
    category: "холодные-роллы",
    title: "Цезарь с тигровыми креветками",
    descr: `рис, томаты, тигровые креветки, листья салата, сыр "Пармезан", соус "Цезарь", нори`,
    price: 350,
    weight: "",
  },
  {
    id: "холодные-роллы-10",
    category: "холодные-роллы",
    title: "Чипс ролл",
    descr: `рис, копченое куриное филе , огурец, томаты, чипсы, нори`,
    price: 320,
    weight: "",
  },
  {
    id: "холодные-роллы-11",
    category: "холодные-роллы",
    title: "Сливочный с креветкой",
    descr: `рис, тигровые креветки, сливочный сыр, авокадо, нори`,
    price: 310,
    weight: "",
  },
  {
    id: "запеченные-роллы-1",
    category: "запеченные-роллы",
    title: "Со снежным крабом",
    descr: `рис, икра "Масаrа", авокадо, снежный краб в остром соусе "Спайси", сыр "Мацарелла", фирменная шапочка`,
    price: 340,
    weight: "",
  },
  {
    id: "запеченные-роллы-2",
    category: "запеченные-роллы",
    title: "С копченой курицей",
    descr: `рис, творожный сыр, копченое куриное филе халяль, листья салата, кунжут, фирменная шапочка, соус "Терияки"`,
    price: 310,
    weight: "",
  },
  {
    id: "запеченные-роллы-3",
    category: "запеченные-роллы",
    title: "Премиум",
    descr: `рис, сливочный сыр, авокадо, норвежский лосось в кисло-соленом соусе, сыр "Мацарелла", икра красная`,
    price: 370,
    weight: "",
  },
  {
    id: "запеченные-роллы-5",
    category: "запеченные-роллы",
    title: "Феникс",
    descr: `рис, икра "Масаrа", куриное филе, сливочный сыр, снежный краб, сыр "Мацарелла", соус "Спайси"`,
    price: 340,
    weight: "",
  },
  {
    id: "запеченные-роллы-6",
    category: "запеченные-роллы",
    title: "С креветкой",
    descr: `рис, икра " Масаrа", сыр сливочный, авокадо, огурец, сыр "Мацарелла", тигровые креветки, соус "Спайси", соус "Унаги" `,
    price: 360,
    weight: "",
  },
  {
    id: "запеченные-роллы-7",
    category: "запеченные-роллы",
    title: "С тунцом",
    descr: `рис, тунец, авокадо, огурец, икра "Масаrа", лук зеленый, фирменная шапочка`,
    price: 350,
    weight: "",
  },
  {
    id: "горячие-роллы-1",
    category: "горячие-роллы",
    title: "Скандинава",
    descr: `рис, норвежский лосось, сливочный сыр, авокадо, темпурный кляр, соус "Унаги", соус "Спайси"`,
    price: 350,
    weight: "",
  },
  {
    id: "горячие-роллы-2",
    category: "горячие-роллы",
    title: "Нэко",
    descr: `рис, снежный краб, сливочный сыр, огурец, темпурный кляр, соус "Унаги", соус "Спайси"`,
    price: 330,
    weight: "",
  },
  {
    id: "горячие-роллы-5",
    category: "горячие-роллы",
    title: "Горячий с креветкой",
    descr: `рис, тигровые креветки, сливочный сыр, авокадо, огурец, темпурный кляр, соус "Спайси"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-6",
    category: "горячие-роллы",
    title: "Горячий с курицей",
    descr: `рис, куриное филе , огурец, соус "Спайси", темпурный кляр`,
    price: 320,
    weight: "",
  },
  {
    id: "горячие-роллы-7",
    category: "горячие-роллы",
    title: "Цезарь в темпуре с курицей",
    descr: `рис, томат, листья салата, куриное филе Халяль, темпурный кляр, соус "Цезарь", сыр "Пармезан"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-8",
    category: "горячие-роллы",
    title: "Цезарь в темпере с  тигровыми креветками",
    descr: `рис, томат, листья салата, тигровые креветки, темперный кляр, соус "Цезарь", сыр "Пармезан"`,
    price: 350,
    weight: "",
  },
  {
    id: "горячие-роллы-9",
    category: "горячие-роллы",
    title: "Кентуки",
    descr: `рис, копченое куриное филе, сыр "Мацарелла", болгарский перец, темперный кляр, соус "Цезарь", соус "Терияки"`,
    price: 330,
    weight: "",
  },
  {
    id: "горячие-роллы-10",
    category: "горячие-роллы",
    title: "Сяомин",
    descr: `рис, снежный краб, творожный сыр, куриное филе, темпурный кляр, соус "Спайси", икра "Масаro"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-11",
    category: "горячие-роллы",
    title: "Магуро",
    descr: `рис, тунец, сливочный сыр, авокадо, зеленый лук, темпурный кляр, кунжут, соус "Унаги"`,
    price: 340,
    weight: "",
  },
  {
    id: "горячие-роллы-12",
    category: "горячие-роллы",
    title: "Мацудо",
    descr: `рис, норвежский лосось, тигровые креветки, сливочный сыр, снежный краб, темперный кляр, соус "Унаги", майонез, кунжут`,
    price: 370,
    weight: "",
  },
  {
    id: "горячие-роллы-13",
    category: "горячие-роллы",
    title: "Калипсо",
    descr: `рис, норвежский лосось, сливочный сыр, тигровые креветки, зеленый лук, соус "Унаги"`,
    price: 360,
    weight: "",
  },
  {
    id: "горячие-роллы-14",
    category: "горячие-роллы",
    title: "Цезарь в темпуре с  лососем",
    descr: `рис, огурец, листья салата, норвежский лосось, темперный кляр, соус "Цезарь", сыр "Пармезан"`,
    price: 340,
    weight: "",
  },
  {
    id: "мини-роллы-1",
    category: "мини-роллы",
    title: "С огурцом",
    descr: `рис, огурец, нори`,
    price: 120,
    weight: "",
  },
  {
    id: "мини-роллы-2",
    category: "мини-роллы",
    title: "С лососем",
    descr: `рис, норвежский лосось, нори`,
    price: 180,
    weight: "",
  },
  {
    id: "мини-роллы-3",
    category: "мини-роллы",
    title: "С креветкой",
    descr: `рис, тигровые креветки, нори`,
    price: 190,
    weight: "",
  },
  {
    id: "мини-роллы-4",
    category: "мини-роллы",
    title: "С авокадо",
    descr: `рис, авокадо, нори`,
    price: 140,
    weight: "",
  },
  {
    id: "мини-роллы-5",
    category: "мини-роллы",
    title: "С курицей",
    descr: `рис, куриное филе, нори`,
    price: 150,
    weight: "",
  },
  {
    id: "салаты-1",
    category: "салаты",
    title: "Греческий",
    descr: `томаты, болгарский перец, огурец, листья салата , творожный сыр, оливковое масло, маслины`,
    price: 180,
    weight: "",
  },
  {
    id: "салаты-2",
    category: "салаты",
    title: "Цезарь",
    descr: `листья салата , оригинальный соус "Цезарь", гренки, сыр "Пармезан", помидоры черри, копченое куриное филе`,
    price: 220,
    weight: "",
  },
  {
    id: "салаты-3",
    category: "салаты",
    title: "Терияки",
    descr: `огурцы, помидоры, листья салата , куриное филе, соус "Терияки", кунжут`,
    price: 200,
    weight: "",
  },
  {
    id: "фастфуд-1",
    category: "фастфуд",
    title: "Картофель ФРИ",
    descr: ``,
    price: 120,
    weight: "",
  },
  {
    id: "фастфуд-2",
    category: "фастфуд",
    title: "Картофель по-деревенски",
    descr: ``,
    price: 120,
    weight: "",
  },
  {
    id: "фастфуд-3",
    category: "фастфуд",
    title: "Наггетсы (6 шт.)",
    descr: ``,
    price: 130,
    weight: "",
  },
  {
    id: "фастфуд-4",
    category: "фастфуд",
    title: "Соус томатный",
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-5",
    category: "фастфуд",
    title: "Соус сырный",
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-6",
    category: "фастфуд",
    title: "Соус цезарь",
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-7",
    category: "фастфуд",
    title: `Острый "Спайси"`,
    descr: ``,
    price: 30,
    weight: "",
  },
  {
    id: "фастфуд-8",
    category: "фастфуд",
    title: "Хот-дог",
    descr: ``,
    price: 120,
    weight: "",
  },
  {
    id: "фастфуд-9",
    category: "фастфуд",
    title: "Хот-дог с сыром и томатами",
    descr: ``,
    price: 140,
    weight: "",
  },
  {
    id: "фастфуд-10",
    category: "фастфуд",
    title: "Сэндвич 'Цезарь'",
    descr: `сэндвичный хлеб, соус цезарь, листья салата, ветчина куриная халяль, томаты, плавленый сыр`,
    price: 170,
    weight: "",
  },
  {
    id: "десерты-1",
    category: "десерты",
    title: "Чизкейк",
    descr: ``,
    price: 130,
    weight: "100 гр",
  },
  {
    id: "десерты-4",
    category: "десерты",
    title: "Мороженое",
    descr: ``,
    price: 70,
    weight: "40 гр (1 шарик)",
  },
  {
    id: "десерты-5",
    category: "десерты",
    title: "Фруктовый салат",
    descr: `банан, апельсин, яблоко, груша, киви, йогурт`,
    price: 140,
    weight: "140 гр",
  },
  {
    id: "кофе-1",
    category: "кофе",
    title: "Эспрессо",
    descr: `крепкий кофе, приготовленный из хорошо обжаренных и тонко помолотых зерен кофе`,
    price: 100,
    weight: "30 мл",
  },
  {
    id: "кофе-2",
    category: "кофе",
    title: "Американо",
    descr: ``,
    price: 120,
    weight: "120 мл",
  },
  {
    id: "кофе-3",
    category: "кофе",
    title: "Капучино",
    descr: ``,
    price: 150,
    weight: "200 мл",
  },
  {
    id: "кофе-4",
    category: "кофе",
    title: "Латте Классический",
    descr: `напиток на основе эспрессо с добавлением вспененного молока`,
    price: 150,
    weight: "200 мл",
  },
  {
    id: "кофе-5",
    category: "кофе",
    title: "Латте с сиропом",
    descr: `напиток на основе эспрессо с добавлением вспененного молока, сироп на выбор`,
    price: 160,
    weight: "200 мл",
  },
  {
    id: "кофе-6",
    category: "кофе",
    title: "Кофе по-Венски",
    descr: `кофе со взбитыми сливками`,
    price: 140,
    weight: "150 мл",
  },
  {
    id: "кофе-7",
    category: "кофе",
    title: "Гляссе",
    descr: ``,
    price: 180,
    weight: "150 мл",
  },
  {
    id: "кофе-8",
    category: "кофе",
    title: "Какао",
    descr: `подается с маршмеллоу`,
    price: 100,
    weight: "200 мл",
  },
  {
    id: "коктейли-молочные-1",
    category: "коктейли-молочные",
    title: "Фирменный Skandilove",
    descr: `ванильное мороженое, банан, молоко, клубничный сироп, взбитые сливки`,
    price: 180,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-2",
    category: "коктейли-молочные",
    title: "Тропический",
    descr: `ананасовый сок, молоко, кокосовый сироп, ванильное мороженое, взбитые сливки`,
    price: 180,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-3",
    category: "коктейли-молочные",
    title: "Клубничный",
    descr: `клубника, молоко, ванильное мороженое, клубничный сироп`,
    price: 170,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-4",
    category: "коктейли-молочные",
    title: "Ванильный",
    descr: `ванильное мороженое, молоко, ваниль`,
    price: 140,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-5",
    category: "коктейли-молочные",
    title: "Шоколадный",
    descr: `ванильное мороженое, банан, какао, молоко`,
    price: 150,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-6",
    category: "коктейли-молочные",
    title: "Бабл Гам",
    descr: `ванильное мороженое, молоко, сироп, маршмеллоу`,
    price: 160,
    weight: "300 мл",
  },
  {
    id: "коктейли-молочные-7",
    category: "коктейли-молочные",
    title: "Классический",
    descr: `фруктовый сок, ванильное мороженое`,
    price: 100,
    weight: "200 мл",
  },
  {
    id: "смузи-1",
    category: "смузи",
    title: "Апельсиновый",
    descr: `банан, апельсин, апельсиновый сок`,
    price: 120,
    weight: "200 мл",
  },
  {
    id: "смузи-2",
    category: "смузи",
    title: "Клубничный",
    descr: `клубника ,банан, апельсиновый сок`,
    price: 140,
    weight: "200 мл",
  },
  {
    id: "фирменные-напитки-1",
    category: "фирменные-напитки",
    title: "Фламинго",
    descr: `газированная вода, лайм, мята, сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-2",
    category: "фирменные-напитки",
    title: "Персиковая жвачка",
    descr: `газированная вода, персиковый сок, сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-3",
    category: "фирменные-напитки",
    title: "Лимонад лимонный ",
    descr: `газированная вода, лимон, сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-4",
    category: "фирменные-напитки",
    title: "Лимонад апельсиновый",
    descr: `газированная вода,апельсин,сироп`,
    price: 100,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-5",
    category: "фирменные-напитки",
    title: "Мохито",
    descr: `газированная вода, лайм, мята, сироп`,
    price: 120,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-6",
    category: "фирменные-напитки",
    title: "Мохито клубничный",
    descr: `газированная вода, клубника, лайм, мята, сироп`,
    price: 125,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-7",
    category: "фирменные-напитки",
    title: "Свежесть лета",
    descr: `содовая вода, яблочный сок, сироп, киви, мята`,
    price: 120,
    weight: "300 мл",
  },
  {
    id: "фирменные-напитки-8",
    category: "фирменные-напитки",
    title: "Лимонад с мармеладом",
    descr: `сок Добрый лимон-лайм, апельсиновый сок, свежий апельсин, мармеладки`,
    price: 120,
    weight: "300 мл",
  },
  {
    id: "холодные-напитки-1",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 60,
    weight: "0,33 л",
  },
  {
    id: "холодные-напитки-3",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 80,
    weight: "0,5 л",
  },
  {
    id: "холодные-напитки-4",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 110,
    weight: "1 л",
  },
  {
    id: "холодные-напитки-5",
    category: "холодные-напитки",
    title: "Добрый Кола",
    descr: ``,
    price: 160,
    weight: "1,5 л",
  },
  {
    id: "холодные-напитки-7",
    category: "холодные-напитки",
    title: "Сок Добрый (в ассортименте)",
    descr: ``,
    price: 170,
    weight: "1 л",
  },
  {
    id: "холодные-напитки-9",
    category: "холодные-напитки",
    title: "Вon Aqua",
    descr: `газированная`,
    price: 50,
    weight: "0,5 л",
  },
  {
    id: "холодные-напитки-10",
    category: "холодные-напитки",
    title: "Вon Aqua",
    descr: `не газированная`,
    price: 50,
    weight: "0,5 л",
  },
  {
    id: "холодные-напитки-11",
    category: "холодные-напитки",
    title: "Сок в ассортименте",
    descr: ``,
    price: 50,
    weight: "200 мл",
  },

  /* {
    id: "фастфуд-11",
    category: "фастфуд",
    title: "",
    descr: ``,
    price: 0,
    weight: "",
  }, */
]

export default dataAllProducts
